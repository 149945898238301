import React, { Component } from 'react'
import Flashcard from './Flashcard/Flashcard'
import './FlashcardPage.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import { pageTransition, pageTransitionDuration } from '../../Util/Animations'
import MDSpinner from 'react-md-spinner'
import { v4 as uuidv4 } from 'uuid';

export default class FlashcardPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            flipped: false,
            initialRender: true,
            flashcardCount: 0,
            flashcards: null,
            flashcardKeyArray: [],
            flashcardSelected: '',
            flashcardIndex: 0,
            currentScore: 100,
            cardsCorrect: 0,
            cardsIncorrect: 0,
            spacedStudying: false,
            spacedCorrect: 0,
            spacedIncorrect: 0,
            originalFlashcardCount: 0,
            isFlashcardClicked: false,
            isCardFlipped: false,
            percentCorrect: 100,
            dragging: false
        }
    }

    softSyncInterval = null

    componentDidMount() {
        this.getCards()
        this.props.flashcardSettingsCallback(() => {
            this.getKeyArray(false)
        })

        if (this.softSyncInterval === null) {
            this.softSyncInterval = setInterval(() => this.props.softSyncHandler(() => this.getCards()), 1 * 60 * 1000)
        }
    }

    componentWillUnmount() {
        document.body.onkeyup = null
        if (this.softSyncInterval !== null) {
            clearInterval(this.softSyncInterval)
        }
        this.props.flashcardSettingsCallback(() => null)
    }

    getCards = () => {
        let flashcardArray = Object.values(this.props.flashcards)
        let flashcardSelected = flashcardArray.length > 0 ? flashcardArray[0].CardGeneratedID : null
        this.setState({flashcards: this.props.flashcards, flashcardSelected}, () => {
            this.getKeyArray(true)
        })
    }

    getKeyArray = (displaySpacedPopup) => {
        let flashcardKeyArray
        let flashcards = {}

        if (this.props.spacedMode && this.props.userSyncData.cd?.length > 0) {
            this.props.userSyncData.cd.forEach((value, index) => {
                let nextReviewDate = new Date(value.NextReviewDate)
                let today = new Date()

                let nextReviewYear = nextReviewDate.getFullYear()
                let thisYear = today.getFullYear()

                let nextReviewMonth = nextReviewDate.getMonth()
                let thisMonth = today.getMonth()

                let nextReviewDay = nextReviewDate.getDate()
                let thisDay = today.getDate()

                if ((nextReviewYear <= thisYear && nextReviewMonth <= thisMonth && nextReviewDay <= thisDay) || this.props.testingSpacedMode) {
                    flashcards[value.cardGeneratedID] = this.state.flashcards[value.cardGeneratedID]
                }
            })
            flashcardKeyArray = this.getFlashcardKeyArray(flashcards)
            if (flashcardKeyArray.length === 0) {
                if (displaySpacedPopup) {
                    this.props.displayConfirmationHandler('Spaced Mode', 'You\'re in spaced mode. Since you don\'t yet have any cards to study today, you\'ll study as you normally would. Your incorrect cards will be assigned review dates for you to review during your next spaced mode session.', () => null, false)
                }
                let tempObject = {}
                this.props.userSyncData.cd.forEach((value, index) => {
                    if (value.NextReviewDate !== null) {
                        tempObject[value.cardGeneratedID] = true
                    }
                })
                Object.values(this.state.flashcards).forEach((value, index) => {
                    if (!tempObject[value.CardGeneratedID]) {
                        flashcards[value.CardGeneratedID] = value
                    }
                })
                if (Object.values(flashcards).length === 0) {
                    this.props.displayConfirmationHandler('No Spaced Cards Left', 'You don\'t have any spaced mode cards to study today in this category, would you like to continue in standard mode?', (userDecision) => {
                        if (userDecision) {
                            this.props.updateSpacedMode(false)
                        } else {
                            this.changePage()
                        }
                    }, true, 'Yes', 'No')
                    flashcards = this.state.flashcards
                }
            } else {
                this.setState({spacedStudying: true})
            }
        } else {
            if (this.props.spacedMode && displaySpacedPopup) {
                this.props.displayConfirmationHandler('Spaced Mode', 'You\'re in spaced mode. Since you don\'t yet have any cards to study today, you\'ll study as you normally would. Your incorrect cards will be assigned review dates for you to review during your next spaced mode session.', () => null, false)
            }
            flashcards = this.state.flashcards
        }

        if (!this.props.shuffle) {
            flashcardKeyArray = this.getFlashcardKeyArray(flashcards)
        } else {
            flashcardKeyArray = this.shuffle(this.getFlashcardKeyArray(flashcards))
        }

        if (this.props.studyIncomplete) {
            let answeredArray = []
            let unansweredArray = []
            let keyMap = {}

            this.props.userSyncData.cd.forEach((syncValue, syncIndex) => {
                if (syncValue.NumAttempts > 0) {
                    keyMap[syncValue.cardGeneratedID] = syncValue
                }
            })

            flashcardKeyArray.forEach((value, index) => {
                if (keyMap[value]) {
                    answeredArray.push(value)
                } else {
                    unansweredArray.push(value)
                }
            })

            flashcardKeyArray = [...unansweredArray ,...answeredArray]
        }

        if (this.props.studyIncorrect) {
            // this.props.userSyncData.cd[0].LastMarkedIncorrect
            // this.props.userSyncData.cd[0].NumAttempts

            let keyMap = {}
            flashcardKeyArray.forEach((value, index) => {
                keyMap[value] = true
            })

            this.props.userSyncData.cd.forEach((syncValue, syncIndex) => {
                if (syncValue.LastMarkedCorrect) {
                    keyMap[syncValue.cardGeneratedID] = false
                }
            })

            let tempArray = []
            Object.keys(keyMap).forEach((value, index) => {
                if (keyMap[value]) {
                    tempArray.push(value)
                }
            })

            flashcardKeyArray = tempArray
        }

        this.setState({
            flashcardKeyArray,
            flashcardCount: flashcardKeyArray.length,
            flashcardSelected: flashcardKeyArray[0],
            flashcardIndex: 0,
            originalFlashcardCount: flashcardKeyArray.length
        }, () => {
            this.props.updateFlashcardKeyArray(flashcardKeyArray)
        })
    }

    getFlashcardKeyArray = (flashcards) => {
        let flashcardKeyArray
        if (this.props.favoritesOnly === 1 && this.props.userSyncData !== null) {
            flashcardKeyArray = []
            let userSyncData = this.props.userSyncData.cd

            userSyncData.forEach((value, index) => {
                if (value.favorited) {
                    flashcardKeyArray.push(`${value.cardGeneratedID}`)
                }
            })
            
            if (flashcardKeyArray.length === 0)
                flashcardKeyArray = Object.keys(flashcards)
        } else {
            flashcardKeyArray = Object.keys(flashcards)
        }

        return flashcardKeyArray
    }

    shuffle = (array) => {
        let currentIndex = array.length, randomIndex
      
        // While there remain elements to shuffle...
        while (currentIndex !== 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex)
          currentIndex--
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]]
        }
      
        return array
      }


    changePage = () => {
        this.props.changePage('Categories')
    }

    favoriteQuestion = (cardId) => {
        this.props.favoriteQuestionHandler(cardId)
    }

    updateCardScore = () => {
        let totalCards = 0
        let cardsCorrect = 0
        let cardsIncorrect = 0

        let flashcardArray = Object.values(this.state.flashcards)

        for (let i = 0; i < flashcardArray.length; ++i) {
            if (flashcardArray[i].cardCorrect === true) {
                totalCards += 1
                cardsCorrect += 1
            } else if (flashcardArray[i].cardCorrect === false) {
                totalCards += 1
                cardsIncorrect += 1
            }
        }

        this.props.updateStatisticsValues()

        this.props.updateCardsCorrect({totalCards: flashcardArray.length, cardsCorrect, cardsIncorrect})

        if (totalCards === 0)
            totalCards = 1

        this.setState({currentScore: Math.floor(cardsCorrect / totalCards * 100)})
    }

    removeSpacedCardHandler = (cardIdString, correct) => {
        let flashcardKeyArray = [...this.state.flashcardKeyArray]
        let lastCard = false
        for(let i = 0; i < flashcardKeyArray.length; ++i) {
            if (i == flashcardKeyArray.length - 1 && flashcardKeyArray[i] === cardIdString) {
                flashcardKeyArray.splice(i, 1)
                lastCard = true
                break
            } else if (flashcardKeyArray[i] === cardIdString) {
                flashcardKeyArray.splice(i, 1)
                break
            }
        }
        
        let spacedCorrect = this.state.spacedCorrect
        let spacedIncorrect = this.state.spacedIncorrect

        if (correct)
            spacedCorrect++
        else
            spacedIncorrect++

        this.setState({
            flashcardKeyArray,
            flashcardCount: this.state.flashcardCount - 1,
            spacedCorrect,
            spacedIncorrect,
            flashcardIndex: lastCard ? 0 : this.state.flashcardIndex,
            flashcardSelected: lastCard ? this.state.flashcardKeyArray[0] : this.state.flashcardSelected
        }, () => {
            if (Object.values(this.state.flashcards).length !== this.state.originalFlashcardCount && this.props.favoritesOnly === 1 && flashcardKeyArray.length === 0) {
                this.props.displayConfirmationHandler('Continue With Spaced Mode?', 'You\'re all out of favorited spaced mode cards. Would you like to continue with your unfavorited spaced mode cards? Or cancel and switch to standard mode to continue with your favorited cards?', (userDecision) => {
                    if (userDecision) {
                        this.props.enableFavoritesOnly()
                    } else {
                        this.props.updateSpacedMode(false)
                        setTimeout(() => {
                            this.getCards()
                        }, 5)
                    }
                }, true, 'Continue', 'Cancel')
            } else if (flashcardKeyArray.length === 0) {
                this.props.displaySpacedModePopupHandler(this.state.spacedCorrect, this.state.spacedIncorrect, (userDecision) => {
                    if (userDecision) {
                        this.setState({
                            spacedStudying: true,
                            spacedCorrect: 0,
                            spacedIncorrect: 0
                        }, () => {
                            this.getCards()
                        })
                    } else {
                        this.getCards()
                    }
                }, ((Object.values(this.props.flashcards).length === this.state.originalFlashcardCount) && this.props.favoritesOnly === 1) ? false : true)
            }
        })
    }

    markCardAnswered = (correct) => {
        let flashcards = {...this.state.flashcards}
        let flashcardSelected = this.state.flashcardSelected
        let currentCard = flashcards[flashcardSelected]
        let syncData = {
            cardGeneratedID: `${currentCard.CardGeneratedID}`,
            completionDate: `${(new Date).toJSON()}`,
            correct,
            incorrect: !correct,
            uniqueAttemptID: uuidv4()
        }
        if (this.props.spacedMode) {
            this.removeSpacedCardHandler(`${currentCard.CardGeneratedID}`, correct)
        } else if (this.props.studyIncorrect && correct) {
            this.removeStudyIncorrectCardHandler(`${currentCard.CardGeneratedID}`)
        }
        this.props.updateSyncData(syncData, currentCard.FlashcardProductID)

        flashcards[flashcardSelected].cardCorrect = correct
        this.setState({flashcards, isCardFlipped: false}, () => {
            this.updateCardScore()
            if ((!this.props.spacedMode && !this.props.studyIncorrect) || (this.props.studyIncorrect && !correct))
                this.changeCard(true)
            else
                this.changeCard(null)
        })
    }

    changeCard = (nextCard) => {
        let previousCard = this.state.flashcardSelected
        let newCard

        const flashcardArray = [...this.state.flashcardKeyArray]
        console.log({nextCard, flashcardIndex: this.state.flashcardIndex, flashcardCount: this.state.flashcardCount})
        if (nextCard === true) {
            newCard = this.state.flashcardIndex + 1 >= this.state.flashcardCount ? 0 : this.state.flashcardIndex + 1
        } else if (nextCard === false) {
            newCard = this.state.flashcardIndex - 1 < 0 ? this.state.flashcardCount - 1 : this.state.flashcardIndex - 1
        } else {
            newCard = this.state.flashcardIndex
        }

        this.props.verifyLoginStatus()

        this.setState({
            previousCard: previousCard,
            flashcardSelected: flashcardArray[newCard],
            flipped: false,
            flashcardIndex: newCard,
            isCardFlipped: false
        })
    }

    isCardFavorited = () => {
        if (!this.props.userSyncData)
            return false
        
        if (this.state.flashcardSelected === null || this.state.flashcardSelected === undefined)
            return false

        let cardId = this.state.flashcards[this.state.flashcardSelected].CardGeneratedID
        for(let i = 0; i < this.props.userSyncData.cd.length; ++i) {
            if (this.props.userSyncData.cd[i].cardGeneratedID == cardId) {
                return this.props.userSyncData.cd[i].favorited
            }
        }

        return false
    }

    removeStudyIncorrectCardHandler = (cardIdString) => {
        let flashcardKeyArray = [...this.state.flashcardKeyArray]
        let lastCard = false
        for(let i = 0; i < flashcardKeyArray.length; ++i) {
            if (i == flashcardKeyArray.length - 1 && flashcardKeyArray[i] === cardIdString) {
                flashcardKeyArray.splice(i, 1)
                lastCard = true
                break
            } else if (flashcardKeyArray[i] === cardIdString) {
                flashcardKeyArray.splice(i, 1)
                break
            }
        }

        this.setState({
            flashcardKeyArray,
            flashcardCount: this.state.flashcardCount - 1,
            flashcardIndex: lastCard ? 0 : this.state.flashcardIndex,
            flashcardSelected: lastCard ? this.state.flashcardKeyArray[0] : this.state.flashcardSelected
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.settingsStatisticsData !== this.props.settingsStatisticsData || prevProps.showOverallStats !== this.props.showOverallStats) {
            let percentCorrect = 0
            if (this.props.showOverallStats) {
                percentCorrect = this.props.settingsStatisticsData.overallCorrect / (this.props.settingsStatisticsData.overallCorrect + this.props.settingsStatisticsData.overallIncorrect)
            } else {
                percentCorrect = this.props.settingsStatisticsData.currentCorrect / (this.props.settingsStatisticsData.currentCorrect + this.props.settingsStatisticsData.currentIncorrect)
            }

            percentCorrect = Math.floor(percentCorrect * 100)
            this.setState({percentCorrect})
        }
    }

    setFlipped = () => {
        this.setState({isCardFlipped: !this.state.isCardFlipped})
    }

    render() {
        return (
            <motion.div className='flashcardPageContainer' initial={this.props.pageAnimations ? pageTransition.initial : false} animate={this.props.pageAnimations ? pageTransition.in : false} exit={this.props.pageAnimations ? pageTransition.out : false} transition={{ duration: pageTransitionDuration }} key='FlashCardPageContainer'>
                <div className='flashcardPageWrapper container'>
                    <button className='primaryButton flashcardPageBackButton' onClick={this.changePage}> <FontAwesomeIcon icon={solid('arrow-left')} className='flashcardPageBackArrow'/>Categories</button>
                    <div className='flashcardPageProgressBar'>
                        <p className='flashcardProgressText desktopProgressText'>Current Card: {this.state.flashcardIndex + 1}/{this.state.flashcardCount}</p>
                        <p className='flashcardProgressText mobileProgressText'>{this.state.flashcardIndex + 1}/{this.state.flashcardCount}</p>
                        <span className='flashcardProgressDivider' />
                        <p className='flashcardProgressText desktopProgressText'>Percent Correct: {isNaN(this.state.percentCorrect) ? 0 : this.state.percentCorrect}%</p>
                        <p className='flashcardProgressText mobileProgressText'>{isNaN(this.state.percentCorrect) ? 0 : this.state.percentCorrect}%</p>
                    </div>
                    <div className='flashcardCardWrapper' onMouseUp={(!this.state.dragging && this.props.otherAnimations) ? () => this.setFlipped() : null}>
                        <AnimatePresence exitBeforeEnter>
                            {(this.state.flashcards !== null && this.state.flashcardKeyArray.length !== 0 && !this.props.loadingCards) ?
                                <Flashcard
                                    currentCard={this.state.flashcards[this.state.flashcardSelected]}
                                    enlargeImage={this.props.displayImage}
                                    flipAnimation={this.props.flipAnimation}
                                    favoriteQuestion={this.favoriteQuestion}
                                    favorited={this.isCardFavorited()}
                                    changeCard={this.changeCard}
                                    markCardAnswered={this.markCardAnswered}
                                    key={this.state.flashcardSelected}
                                    flashcardSelected={this.state.flashcardSelected}
                                    spaceBarDisabled={this.props.spaceBarDisabled}
                                    index={this.state.flashcardIndex}
                                    userSyncData={this.props.userSyncData}
                                    testingSpacedMode={this.props.testingSpacedMode}
                                    pageAnimations={this.props.pageAnimations}
                                    otherAnimations={this.props.otherAnimations}
                                    isFlashcardClicked={this.state.isFlashcardClicked}
                                    setFlashcardClicked={() => this.setState({isFlashcardClicked: !this.state.isFlashcardClicked})}
                                    isFlipped={this.state.isCardFlipped}
                                    setFlipped={this.setFlipped}
                                    flashcardOtherAnimationsCallback={this.props.flashcardOtherAnimationsCallback}
                                    touchEndCallback={this.props.touchEndCallback}
                                    dragging={this.state.dragging}
                                    setDragging={(newValue) => this.setState({dragging: newValue})}
                                />
                            :
                                <motion.div className='flashcardLoadingContainer' initial={this.props.pageAnimations ? pageTransition.initial : false} animate={this.props.pageAnimations ? pageTransition.in : false} exit={this.props.pageAnimations ? pageTransition.out : false} transition={{ duration: pageTransitionDuration }} key='FlaschardLoadingContainer'>
                                    <MDSpinner size={50} singleColor={this.props.themeColor}/>
                                </motion.div>
                            }
                        </AnimatePresence>
                    </div>
                    <div className='flashcardButtonWrapper'>
                        <button className='secondaryButton flashcardButton' onClick={() => this.markCardAnswered(false)}>Incorrect</button>
                        <button className='primaryButton flashcardButton' onClick={() => this.markCardAnswered(true)}>Correct</button>
                    </div>
                </div>
            </motion.div>
        )
    }
}
