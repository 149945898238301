

const isLicenseExpired = (licenseExpirationDate, userLoginDate) => {
    const licenseExpiration = new Date(licenseExpirationDate)
    const loginDate = new Date(userLoginDate)
    const today = new Date()

    if (licenseExpiration < today && licenseExpiration < loginDate) {
        return true
    }

    return false
}

export default isLicenseExpired