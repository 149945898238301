import React, { useEffect, useState } from 'react'
import './Flashcard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { AnimatePresence, motion, useAnimation  } from 'framer-motion'
import { cardTransition, transitionDuration } from '../../../Util/Animations'
import displayNotification from '../../../Util/DisplayNotification'

const Flashcard = (props) => {

    const [offset, setOffset] = useState(1)
    const [initialRender, setInitialRender] = useState(true)
    const [mcOptionSelected, setMcOption] = useState(-1)
    const [touchMoving, setTouchMoving] = useState(false)
    const [touchStartX, setTouchStartX] = useState(0)
    const [touchStartY, setTouchStartY] = useState(0)

    const enlargeImage = (e, image) => {
        e.stopPropagation()

        let mouseX
        let mouseY

        if (e.changedTouches) {
            // console.log('Touch End')
            mouseX = e.changedTouches[0].clientX
            mouseY = e.changedTouches[0].clientY
        } else if (e.clientX) {
            // console.log('Mouse End')
            mouseX = e.clientX
            mouseY = e.clientY
        } else {
            return
        }

        const x = Number(localStorage.getItem('x'))
        localStorage.removeItem('x')
        const y = Number(localStorage.getItem('y'))
        localStorage.removeItem('y')

        const xDiff = x - mouseX
        const yDiff = y - mouseY

        // console.log(xDiff, yDiff)

        if ((xDiff < 10 && xDiff > -10 && yDiff < 10 && yDiff > -10) || props.otherAnimations)
            props.enlargeImage(convertByteToImage(image))
    }

    const markImageSelected = () => {
        localStorage.setItem('imageSelected', 'true')
    }

    const favoriteQuestion = (e) => {
        e.stopPropagation()
        props.favoriteQuestion(props.currentCard.CardGeneratedID)
    }

    const removeClicked = (e) => {
        e.stopPropagation()
    }

    const animControls = useAnimation()

    const style = {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
    }

    const touchStartHandler = (e) => {
        // console.log('Touch Start')
        let x = 0
        let y = 0
        if (e.touches) {
            x = e.touches[0].clientX
            y = e.touches[0].clientY
        } else {
            x = e.clientX
            y = e.clientY
        }
        setTouchStartX(x)
        setTouchStartY(y)
        // console.log(x,y)

        if (props.otherAnimations) {
            return
        }

        localStorage.setItem('mouseDown', 'true')
        localStorage.setItem('x', `${x}`)
        localStorage.setItem('y', `${y}`)

        // console.log({x, y})
    }

    const touchEndHandler = (e) => {
        setTouchMoving(false)
        setTouchStartX(0)
        setTouchStartY(0)
        // console.log('Touch End')
        let mouseDown = localStorage.getItem('mouseDown')
        let imageSelected = localStorage.getItem('imageSelected')

        if (imageSelected === 'true') {
            localStorage.removeItem('imageSelected')
            localStorage.removeItem('mouseDown')
            return
        }
        
        if (mouseDown !== 'true') {
            return
        }

        // displayNotification('Touch End')
        localStorage.removeItem('mouseDown')

        let mouseX
        let mouseY

        if (e.changedTouches) {
            mouseX = e.changedTouches[0].clientX
            mouseY = e.changedTouches[0].clientY
        } else if (e.clientX) {
            mouseX = e.clientX
            mouseY = e.clientY
        } else {
            return
        }

        const x = Number(localStorage.getItem('x'))
        localStorage.removeItem('x')
        const y = Number(localStorage.getItem('y'))
        localStorage.removeItem('y')

        const xDiff = x - mouseX
        const yDiff = y - mouseY

        if (xDiff > 50 && xDiff > yDiff) {
            props.changeCard(true)
        } else if (yDiff > 50 && yDiff > xDiff) {
            props.markCardAnswered(true)
        } else if (xDiff < -50 && xDiff < yDiff) {
            props.changeCard(false)
        } else if (yDiff < -50 && yDiff < xDiff) {
            props.markCardAnswered(false)
        } else if (xDiff < 5 && xDiff > -5 && yDiff < 5 && yDiff > -5) {
            props.setFlipped()
        }
    }

    const touchMoveHandler = (e) => {
        e.preventDefault()
        setTouchMoving(true)
        // console.log('Touch Move', touchStartX, touchStartY)
        if (!props.otherAnimations) {
            return
        }

        let offsetX = touchStartX - e.touches[0].clientX
        let offsetY = touchStartY - e.touches[0].clientY

        // console.log(offsetX, offsetY)

        if (Math.abs(offsetX) > 150 || Math.abs(offsetY) > 150) {
            setOffset(0)
        } else {
            let offsetValue = Math.abs(offsetX) > Math.abs(offsetY) ? offsetX : offsetY
            setOffset(1 - Math.abs(offsetValue) / 150)
        }
    }

    useEffect(() => {
        props.touchEndCallback(touchEndHandler)

        setTimeout(() => {
            setInitialRender(false)
        }, 50)
        return () => {
            localStorage.removeItem('mouseDown')
            localStorage.removeItem('x')
            localStorage.removeItem('y')
            props.setDragging(false)
            props.touchEndCallback(null)
            props.flashcardOtherAnimationsCallback(() => null)
            document.body.onkeyup = (e) => null
        };
    }, [])

    useEffect(() => {
        fixFontSize()
    }, [props.isFlipped])

    const fixFontSize = () => {
        let fontSize = 20
        if (!initialRender) {
            setTimeout(() => {
                let interval = setInterval(() => {
                    let el = document.getElementById('flashcardQuestionWrapper')
                    let textEl = document.getElementById('flashcardText')
                    if (!el) {
                        clearInterval(interval)
                    }
                    if (el.clientHeight < el.scrollHeight && fontSize > 12) {
                        textEl.setAttribute("style", `font-size: ${fontSize--}px`);
                    } else {
                        clearInterval(interval)
                    }
                }, 1)
            }, transitionDuration + 176)
        }
    }

    const animateDirection = (direction, distance) => {
        if (!props.otherAnimations) {
            switch(direction) {
                case 'left':
                    props.changeCard(false)
                    break
                case 'right':
                    props.changeCard(true)
                    break
                case 'up':
                    props.markCardAnswered(true)
                    break
                case 'down':
                    props.markCardAnswered(false)
                    break
            }
        } else {
            switch(direction) {
                case 'left':
                    animControls.start({ x: distance, opacity: 0, transition: {duration: 0.2} });
                    setTimeout(() => {
                        props.changeCard(false)
                    }, 200)
                    break
                case 'right':
                    animControls.start({ x: -distance, opacity: 0, transition: {duration: 0.2} });
                    setTimeout(() => {
                        props.changeCard(true)
                    }, 200)
                    break
                case 'up':
                    animControls.start({ y: -distance, opacity: 0, transition: {duration: 0.2} });
                    setTimeout(() => {
                        props.markCardAnswered(true)
                    }, 200)
                    break
                case 'down':
                    animControls.start({ y: distance, opacity: 0, transition: {duration: 0.2} });
                    setTimeout(() => {
                        props.markCardAnswered(false)
                    }, 200)
                    break
            }
        }
    }

    document.body.onkeyup = (e) => {
        if (e.key === 'ArrowLeft') {
            animateDirection('left', 50)
        } else if (e.key === 'ArrowRight') {
            animateDirection('right', 50)
        } else if (e.key === 'ArrowUp') {
            animateDirection('up', 50)
        } else if (e.key === 'ArrowDown') {
            animateDirection('down', 50)
        } else if (e.key === ' '){
            if (!props.spaceBarDisabled)
                props.setFlipped()
        } else if (e.key === 'f') {
            favoriteQuestion(e)
        }
    }

    const selectMultipleChoice = (e, optionSelected) => {
        e.stopPropagation()
        setMcOption(optionSelected)
    }

    const handleDoubleClick = (e, optionSelected) => {
        e.stopPropagation()
        displayNotification(`Option ${optionSelected} Selected`)
    }

    const convertByteToImage = (byteString) => {
        let imageString = `data:image/png;base64,${byteString}`
        imageString = imageString.replace(/['"]+/g, '')
        return imageString
    }

    const getNextReviewDate = () => {
        let nextReviewDate = ""

        if (!props.userSyncData.cd) {
            return "No sync data found"
        }

        for(let i = 0; i < props.userSyncData.cd.length; ++i) {
            if (props.userSyncData.cd[i].cardGeneratedID == props.currentCard.CardGeneratedID) {
                nextReviewDate = props.userSyncData.cd[i].NextReviewDate
                break
            }
        }

        if (nextReviewDate !== "") {
            return nextReviewDate
        } else {
            return "No next review date"
        }
    }

    const getCardCompletedText = () => {
        let currentCardId = props.currentCard.CardGeneratedID
        let cardCompleted = false
        let cardMarkedCorrect = false
        let cardCompletedDate = ""

        if (!props.userSyncData.cd) {
            return "No sync data found"
        }

        // console.log(props.userSyncData)

        for(let i = 0; i < props.userSyncData.cd.length; ++i) {
            if (props.userSyncData.cd[i].cardGeneratedID == currentCardId) {
                // console.log(props.userSyncData.cd[i])
                cardCompleted = props.userSyncData.cd[i].NumAttempts > 0 ? true : false
                if (!cardCompleted)
                    return null
                cardMarkedCorrect = props.userSyncData.cd[i].LastMarkedCorrect ? true : false
                // console.log(cardCompleted)
                cardCompletedDate = props.userSyncData.cd[i].cc[props.userSyncData.cd[i].cc.length - 1].completionDate
                break
            }
        }

        // Format completion date to MM/DD/YY
        let completionDate = new Date(cardCompletedDate)
        let month = completionDate.getMonth() + 1
        let day = completionDate.getDate()
        let year = completionDate.getFullYear().toString().substring(2, 4)
        let formattedCompletionDate = `${month}/${day}/${year}`

        if (cardCompleted) {
            return <p className='flashcardLastAnsweredText'>Last Marked <strong style={{fontWeight: '600'}}>{cardMarkedCorrect ? 'Correct' : 'Incorrect'}</strong> on <strong style={{fontWeight: '600'}}>{formattedCompletionDate}</strong></p>
        } else {
            return null
        }
    }

    return (
        <motion.div
            className='flashcardWrapper'
            drag={props.otherAnimations}
            animate={props.otherAnimations ? animControls : false}
            dragConstraints={{
                left: !props.otherAnimations ? 0 : -75,
                right: !props.otherAnimations ? 0 : 75,
                top: !props.otherAnimations ? 0 : -75,
                bottom: !props.otherAnimations ? 0 : 75
            }}
            dragElastic={!props.otherAnimations ? 0 : 0.2}
            style={{...style, opacity: offset > 0.5 ? offset : 0.5, animationDuration: !props.otherAnimations ? '0ms' : ''}}
            dragSnapToOrigin={true}
            onMouseDown={touchStartHandler}
            onTouchStart={touchStartHandler}
            onTouchMove={touchMoveHandler}
            // onTouchEnd={touchEndHandler}
            // onMouseUp={touchEndHandler}
            onDragStart={(event, info) => {
                if (!props.otherAnimations) {
                    return
                }
                console.log('drag started')
                props.setDragging(true)
            }}
            onDrag={(event, info) => {
                // Check if touchMoving is false. If so, we are on a desktop and handle the movement with drag
                // Otherwise, this is handled by onTouchMove
                if (!touchMoving) {
                    if (!props.otherAnimations) {
                        return
                    }
    
                    if (Math.abs(info.offset.x) > 150 || Math.abs(info.offset.y) > 150) {
                        setOffset(0)
                    } else {
                        let offsetValue = Math.abs(info.offset.x) > Math.abs(info.offset.y) ? info.offset.x : info.offset.y
                        setOffset(1 - Math.abs(offsetValue) / 150)
                    }
                }
            }}
            onDragEnd={(event, info) => {
                if (!props.otherAnimations) {
                    return
                }
                console.log('drag ended')
                setTimeout(() => {
                    props.setDragging(false)
                }, 10)
                
                if (info.offset.y > 50) {
                    animateDirection('down', 200)
                } else if (info.offset.y < -50) {
                    animateDirection('up', 200)
                } else if (info.offset.x > 50) {
                    animateDirection('left', 200)
                } else if (info.offset.x < -50) {
                    animateDirection('right', 200)
                } else {
                    setOffset(1)
                }
            }}
        >
            <AnimatePresence exitBeforeEnter>
                {!props.isFlipped ? 
                    <motion.div
                        className='flashcardContainer'
                        style={{height: window.innerHeight - 180, gridTemplateRows: `50px ${window.innerHeight - 280}px 50px`}}
                        key={`flashcardFront ${props.flashcardSelected}`}
                        initial={props.otherAnimations ? cardTransition.initial : false}
                        animate={props.otherAnimations ? cardTransition.in : false}
                        exit={props.otherAnimations ? cardTransition.out : false}
                        transition={{ duration: initialRender ? 0 : transitionDuration }}
                    >
                        <p className='flashcardCollectionTitle'>{props.currentCard.CategoryName}</p>
                        <div className='flashcardQuestionWrapper' id="flashcardQuestionWrapper" style={{maxHeight: window.innerHeight - 280}}>
                            {props.currentCard.FrontImageByte && <img className='flashcardImage' onLoad={fixFontSize()} draggable={false} src={convertByteToImage(props.currentCard.FrontImageByte)} onMouseDown={() => markImageSelected()} onTouchStart={() => markImageSelected()} onMouseUp={(e) => enlargeImage(e, props.currentCard.FrontImageByte)}/>}
                            <h2 className='flashcardText' style={{marginBottom: props.index === 1 ? 30 : 0}} id='flashcardText'>{props.currentCard.FrontOfCard}</h2>
                            {/* Code below is for multiple choice questions */}
                            { false &&
                                <div className='flashcardMCWrapper'>
                                    <button className={`flashcardMCButton ${mcOptionSelected === 1 ? 'flashcardMCButtonSelected' : ''}`} onClick={(e) => selectMultipleChoice(e, 1)} onDoubleClick={(e) => handleDoubleClick(e, 1)}><span>A)</span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</button>
                                    <button className={`flashcardMCButton ${mcOptionSelected === 2 ? 'flashcardMCButtonSelected' : ''}`} onClick={(e) => selectMultipleChoice(e, 2)} onDoubleClick={(e) => handleDoubleClick(e, 2)}><span>B)</span>Lorem ipsum dolor sit amet</button>
                                    <button className={`flashcardMCButton ${mcOptionSelected === 3 ? 'flashcardMCButtonSelected' : ''}`} onClick={(e) => selectMultipleChoice(e, 3)} onDoubleClick={(e) => handleDoubleClick(e, 3)}><span>C)</span>Lorem ipsum</button>
                                    <button className={`flashcardMCButton ${mcOptionSelected === 4 ? 'flashcardMCButtonSelected' : ''}`} onClick={(e) => selectMultipleChoice(e, 4)} onDoubleClick={(e) => handleDoubleClick(e, 4)}><span>D)</span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</button>
                                </div>
                            }
                        </div>
                        {props.testingSpacedMode &&
                            <p className='testingSpacedModeText'>{getNextReviewDate()}</p>
                        }
                        {getCardCompletedText()}
                        <p className='flashcardQALogo'>Q</p>
                        <button className='flashcardStar' onMouseDown={removeClicked} onTouchStart={removeClicked} onMouseUp={favoriteQuestion}><FontAwesomeIcon icon={props.favorited ? solid('star') : regular('star')} className='flashcardStarIcon'/></button>
                    </motion.div>
                :
                    <motion.div
                        className='flashcardContainer'
                        style={{height: window.innerHeight - 180, gridTemplateRows: `50px ${window.innerHeight - 280}px 50px`}}
                        key={`flashcardBack ${props.flashcardSelected}`}
                        initial={props.otherAnimations ? cardTransition.initial : false}
                        animate={props.otherAnimations ? cardTransition.in : false}
                        exit={props.otherAnimations ? cardTransition.out : false}
                        transition={{ duration: initialRender ? 0 : transitionDuration }}
                    >
                        <p className='flashcardCollectionTitle'>{props.currentCard.CategoryName}</p>
                        <div className='flashcardQuestionWrapper' id="flashcardQuestionWrapper" style={{maxHeight: window.innerHeight - 280}}>
                            {props.currentCard.BackImageByte && <img className='flashcardImage' onLoad={fixFontSize()} draggable={false} src={convertByteToImage(props.currentCard.BackImageByte)} onMouseDown={() => markImageSelected()} onTouchStart={() => markImageSelected()} onMouseUp={(e) => enlargeImage(e, props.currentCard.BackImageByte)}/>}
                            <h2 className='flashcardText' id='flashcardText'>{props.currentCard.BackOfCard}</h2>
                        </div>
                        {props.testingSpacedMode &&
                            <p className='testingSpacedModeText'>{getNextReviewDate()}</p>
                        }
                        {getCardCompletedText()}
                        <p className='flashcardQALogo'>A</p>
                        <button className='flashcardStar' onMouseDown={removeClicked} onTouchStart={removeClicked} onMouseUp={favoriteQuestion}><FontAwesomeIcon icon={props.favorited ? solid('star') : regular('star')} className='flashcardStarIcon'/></button>
                    </motion.div>
                }
            </AnimatePresence>
        </motion.div>
    )
}

export default Flashcard
