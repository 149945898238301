import React from 'react'
import './SpacedModePopup.css'
import { motion } from 'framer-motion'
import { backdropTransition, backdropTransitionDuration, popupTransition, transitionDuration } from '../../Util/Animations'

export default function SpacedModePopup(props) {
    return (
        <div className='spacedModePopupPageWrapper'>
            <motion.div className='spacedModePopupBackdrop' initial={backdropTransition.initial} animate={backdropTransition.in} exit={backdropTransition.out} transition={{ duration: backdropTransitionDuration }} key='spacedModeBackdrop'/>
            <motion.div className='spacedModePopupContainer' initial={popupTransition.initial} animate={popupTransition.in} exit={popupTransition.out} transition={{ duration: transitionDuration }} key='spacedModeContainer'>
                <div className='spacedModePopupSquare' style={{gridColumn: 'span 2'}}>
                    <h2 className='spacedModePopupTitle'>Spaced Mode Review</h2>
                </div>
                <div className='spacedModePopupSquare'>
                    <h2 className='spacedModePopupQuantity'>{props.correct}</h2>
                    <p className='spacedModePopupQuantityTitle'>Today's Cards Correct</p>
                </div>
                <div className='spacedModePopupSquare'>
                    <h2 className='spacedModePopupQuantity'>{props.missed}</h2>
                    <p className='spacedModePopupQuantityTitle'>Today's Cards Missed</p>
                </div>
                <div className='spacedModePopupSquare' style={{gridColumn: 'span 2'}}>
                    <h2 className='spacedModePopupDate'>{props.nextReviewDate}</h2>
                    <p className='spacedModePopupQuantityTitle'>Next Review Date</p>
                </div>
                <button className='primaryButton spacedModePopupButton' style={{gridColumn: 'span 2'}} onClick={props.continue}>Continue</button>
            </motion.div>
        </div>
    )
}
