import React from 'react'
import './Switch.css'

export default function Switch(props) {
    return (
        <button className='switchToggleContainer' type="button" onClick={props.onClick} style={{backgroundColor: props.backgroundColor, transitionDuration: props.otherAnimations ? '200ms' : '0ms'}}>
            <div className='switchToggleIcon' style={{left: props.value ? '12px' : '-12px', transitionDuration: props.otherAnimations ? '200ms' : '0ms'}}/>
        </button>
    )
}
