import React, { Component } from 'react'
import './CategoriesPage.css'
import { AnimatePresence, motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faGear, faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import handleError from '../../Util/HandleError'
import { pageTransition, pageTransitionDuration, popupTransition, popupTransitionDuration } from '../../Util/Animations'
import MDSpinner from 'react-md-spinner'
import Quotes from '../../Quotes.json'

const Checkbox = (props) => {
    return(
        <div className={`checkboxContainer ${props.isChecked ? 'checkboxContainerSelected' : ''}`} onClick={props.onClick} style={{height: props.size, width: props.size}}>
            {props.isChecked &&
                <FontAwesomeIcon icon={faCheck} style={{fontSize: props.checkmarkSize ? props.checkmarkSize : 16}} className='checkboxCheck' />
            }
        </div>
    )
}

const CategoryContainer = (props) => {
    return (
        <motion.div className='categoryContainer' onClick={props.onClick} initial={props.otherAnimations ? pageTransition.initial: false} animate={props.otherAnimations ? pageTransition.in: false} exit={props.otherAnimations ? pageTransition.out: false} transition={{ duration: (pageTransitionDuration / 3) * props.index }}>
            <Checkbox
                isChecked={props.selected}
                size={16}
                checkmarkSize={12}
                onClick={() => null}
            />
            <p className='categoriesText'>{props.title} ({props.quantity} Cards)</p>
        </motion.div>
    )
}

export default class CategoriesPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categoriesExpanded: false,
            categoriesSelected: false,
            getCardsFetchOutstanding: false,
            categories: [],
            pageDisplayed: 'categoriesPage',
            quoteIndexDisplayed: this.getRandomQuoteIndex()
        }
    }

    quoteTimer = null

    async componentDidMount() {
        if (this.props.cardsArray === null) {
            await this.props.getCards()
        } else {
            this.getCategories()
        }
        this.quoteTimer = setInterval(() => {
            this.setState({quoteIndexDisplayed: this.getRandomQuoteIndex()})
        }, 5000)
    }

    componentWillUnmount() {
        clearInterval(this.quoteTimer)
    }

    getRandomQuoteIndex = () => {
        let randomIndex = Math.floor(Math.random() * Quotes.length)
        
        if (randomIndex === this.state?.quoteIndexDisplayed) {
            randomIndex = 1
        }

        return randomIndex
    }

    componentDidUpdate(prevProps) {
        if (this.props.cardsArray !== prevProps.cardsArray && this.props.cardsArray !== null) {
            this.getCategories()
        }
    }

    getCategories = () => {
        let categories = []

        this.props.cardsArray.forEach((card, index) => {
            if (categories.some(({title}) => title === card.cf.CategoryName)) {
                for(let i = 0; i < categories.length; ++i) {
                    if (categories[i].title === card.cf.CategoryName) {
                        categories[i].count++
                        break
                    }
                }
            } else {
                categories.push({
                    title: card.cf.CategoryName,
                    count: 1,
                    selected: true
                })
            }
        })

        this.setState({categories})
    }

    selectCategoriesHandler = (e, index = null) => {
        e.stopPropagation()
        let categories = [...this.state.categories]
        if (index === null) {
            if (categories.every(({selected}) => selected === true))
                for(let i = 0; i < categories.length; ++i)
                    categories[i].selected = false
            else
                for(let i = 0; i < categories.length; ++i)
                    categories[i].selected = true
        } else
            categories[index].selected = !categories[index].selected

        this.setState({categories})
    }

    changePage = (e, newPage) => {
        e.stopPropagation()
        this.props.changePage(newPage)
    }

    convertBytesToMB = (byteAmount) => {
        return (byteAmount / 1000000).toFixed(1)
    }

    render() {
        return (
            <motion.div className='categoriesPageWrapper' initial={this.props.pageAnimations ? pageTransition.initial : false} animate={this.props.pageAnimations ? pageTransition.in : false} exit={this.props.pageAnimations ? pageTransition.out : false} transition={{ duration: pageTransitionDuration }} key='CategoriesPageWrapper'>
                <AnimatePresence exitBeforeEnter>
                {/* {false ? */}
                {this.props.cardsArray !== null ?
                    <motion.div className='categoriesPageContainer' initial={this.props.pageAnimations ? pageTransition.initial : false} animate={this.props.pageAnimations ? pageTransition.in : false} exit={this.props.pageAnimations ? pageTransition.out : false} transition={{ duration: pageTransitionDuration }} key='CategoriesPageContainer'>
                        <div className='categoriesMainContainer' onClick={this.props.updateSpacedMode}>
                            <Checkbox
                                isChecked={this.props.spacedMode}
                                size={20}
                                onClick={() => null}
                                checkmarkSize={16}
                            />
                            <p className='categoriesMainText'>Spaced Mode</p>
                            <button className='categoriesSpacedModeSettingsButton' onClick={(e) => this.changePage(e, 'SpacedModeSettings')}>
                                <FontAwesomeIcon icon={faGear} className='spacedModeSettingsIcon' />
                            </button>
                        </div>
                        <div className='categoriesMainContainer categoriesDropdownContainer' onClick={() => this.setState({categoriesExpanded: !this.state.categoriesExpanded})}>
                            <Checkbox
                                isChecked={this.state.categories.every(({selected}) => selected === true)}
                                size={20}
                                onClick={this.selectCategoriesHandler}
                                checkmarkSize={16}
                            />
                            <p className='categoriesMainText'>Categories (All)</p>
                            <button className={`categoriesExpandCategoriesButton ${this.state.categoriesExpanded ? '' : 'categoriesExpandedButtonRotated'}`}/>
                        </div>
                        {this.state.categoriesExpanded &&
                            <div className='categoriesWrapper'>
                                <AnimatePresence>
                                    {this.state.categories.map((value, index) => (
                                        <CategoryContainer
                                            key={`CategoryContainer - ${value.title}`}
                                            title={value.title}
                                            selected={value.selected}
                                            quantity={value.count}
                                            onClick={(e) => this.selectCategoriesHandler(e, index)}
                                            index={index}
                                            otherAnimations={this.props.otherAnimations}
                                        />
                                    ))}
                                </AnimatePresence>
                            </div>
                        }
                    </motion.div>
                :
                    <motion.div className='loginButtonLoaderWrapper' initial={this.props.pageAnimations ? pageTransition.initial: false} animate={this.props.pageAnimations ? pageTransition.in: false} exit={this.props.pageAnimations ? pageTransition.out: false} transition={{ duration: pageTransitionDuration }} key='CategoriesLoader'>
                        <MDSpinner size={35} singleColor={this.props.themeColor}/>
                        {this.props.downloadSize === 0 ?
                            <p className='categoriesDownloadProgressText'>Preparing Download</p>
                        :
                            <div className='categoriesDownloadProgressTextContainer' style={{textAlign: 'center'}}>
                                <p className='categoriesDownloadProgressText'>{Math.floor((this.props.downloadProgress / this.props.downloadSize) * 100)}% ({this.convertBytesToMB(this.props.downloadProgress)} MB / {this.convertBytesToMB(this.props.downloadSize)} MB)</p>
                                <p className='categoriesDownloadProgressQuoteText'>{Quotes[this.state.quoteIndexDisplayed]}</p>
                                <p className='categoriesDownloadProgressSubtext'>Downloading card set. Please do not close the app. (We will only have to do this once)</p>
                            </div>
                        }
                    </motion.div>
                }
                </AnimatePresence>
                <AnimatePresence exitBeforeEnter>
                    {/* {false ? */}
                    {this.props.cardsArray !== null ?
                        <motion.button className='categoriesStartStudyingButton' initial={this.props.pageAnimations ? pageTransition.initial : false} animate={this.props.pageAnimations ? pageTransition.in : false} exit={this.props.pageAnimations ? pageTransition.out : false} transition={{ duration: pageTransitionDuration }} key='CategoriesStartStudyingButton' onClick={() => this.props.startStudyingHandler(this.state.categories)}>Start Studying <FontAwesomeIcon className='categoriesStartStudyingArrow' icon={faArrowRight}/></motion.button>
                    :
                        <motion.div initial={this.props.pageAnimations ? pageTransition.initial : false} animate={this.props.pageAnimations ? pageTransition.in : false} exit={this.props.pageAnimations ? pageTransition.out : false} transition={{ duration: pageTransitionDuration }} key='CategoriesLoaderPlaceholder' />
                    }
                </AnimatePresence>
            </motion.div>
        )
    }
}
