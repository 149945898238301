import React from 'react'
import './UnauthenticatedPopup.css'
import { motion } from 'framer-motion'
import { popupTransition, backdropTransition, transitionDuration, backdropTransitionDuration } from '../../Util/Animations'

export default function UnauthenticatedPopup(props) {
    return (
        <div className='unauthenticatedPageWrapper'>
            <motion.div className='unauthenticatedBackdrop' initial={backdropTransition.initial} animate={backdropTransition.in} exit={backdropTransition.out} transition={{ duration: backdropTransitionDuration }} key='unauthenticatedBackdrop'/>
            <motion.div className='unauthenticatedContainer' initial={popupTransition.initial} animate={popupTransition.in} exit={popupTransition.out} transition={{ duration: transitionDuration }} key='unauthenticatedContainer'>
                {props.licenseExpired ? 
                    <h2 className='unauthenticatedHeader'>Your license has expired</h2>
                :
                    <h2 className='unauthenticatedHeader'>You have been signed out</h2>
                }
                {props.licenseExpired ?
                    <p className='unauthenticatedBody'>Your license has expired. If you need more time to study, you can purchase a subscription extension at <a style={{color: props.themeColor}} target="_blank" href="https://www.ccscases.com">CCS Cases.com</a>.<br /><br />Thank you for using CCS cards!</p>
                :
                    <p className='unauthenticatedBody'>Your login token has expired. This is because you've been logged in for over 48 hours or you logged in on another device. <br /><br /> If this is an error, then your account may have been compromised - reset your password immediately and contact us if further assistance is necessary.</p>
                }
                <button className='primaryButton unauthenticatedButton' onClick={() => props.closePopup(false, false)}>Close</button>
            </motion.div>
        </div>
    )
}
