import React from 'react'
import './ConfirmationPopup.css'
import { motion } from 'framer-motion'
import { backdropTransition, backdropTransitionDuration, popupTransition, transitionDuration } from '../../Util/Animations'

export default function ConfirmationPopup(props) {
    return (
        <div className='confirmationPopupPageWrapper'>
            <motion.div className='confirmationPopupPageBackdrop' initial={backdropTransition.initial} animate={backdropTransition.in} exit={backdropTransition.out} transition={{ duration: backdropTransitionDuration }} key='confirmationBackdrop'/>
            <motion.div className='confirmationPopupContainer' initial={popupTransition.initial} animate={popupTransition.in} exit={popupTransition.out} transition={{ duration: transitionDuration }} key='confirmationContainer'>
                <h2 className='confirmationPopupHeader'>{props.header}</h2>
                <p className='confirmationPopupText'>{props.body}</p>
                <div className='confirmationPopupButtonContainer'>
                    {props.enableSecondaryButton &&
                        <button className='confirmationPopupButton secondaryButton' onClick={() => props.confirm(false)}>{props.secondaryButtonText ? props.secondaryButtonText : 'Cancel'}</button>
                    }
                    <button className='confirmationPopupButton primaryButton' style={{gridColumn: props.enableSecondaryButton ? '2' : 'span 2'}} onClick={() => props.confirm(true)}>{props.primaryButtonText ? props.primaryButtonText : 'Confirm'}</button>
                </div>
            </motion.div>
        </div>
    )
}
