import { faSquareCheck } from '@fortawesome/free-regular-svg-icons'
import { faClipboardList, faSquareXmark, faStar } from '@fortawesome/pro-regular-svg-icons'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnimatePresence, motion } from 'framer-motion'
import React, { Component } from 'react'
import Switch from '../../../Util/Switch/Switch'
import './SettingsStatistics.css'
import { appearTransition, transitionDuration, openTop } from '../../../Util/Animations'

export default class SettingsStatistics extends Component {
    constructor(props) {
        super(props)
        this.state = {
            statsInfoContainerTop: 200,
            displayInfoContainer: false,
            correct: props.settingsStatisticsData.overallCorrect,
            incorrect: props.settingsStatisticsData.overallIncorrect,
            favorited: props.settingsStatisticsData.overallFavorited,
            unanswered: props.settingsStatisticsData.overallUnanswered
        }
    }

    infoClickHandler = (e) => {
        if (this.state.displayInfoContainer) {
            this.setState({displayInfoContainer: false})
        } else {
            this.setState({statsInfoContainerTop: e.clientY - 49, displayInfoContainer: true})
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.settingsStatisticsData !== this.props.settingsStatisticsData) {
            if (!this.props.showOverallStats) {
                this.setState({
                    correct: this.props.settingsStatisticsData.currentCorrect,
                    incorrect: this.props.settingsStatisticsData.currentIncorrect,
                    favorited: this.props.settingsStatisticsData.currentFavorited,
                    unanswered: this.props.settingsStatisticsData.currentUnanswered,
                })
            } else {
                this.setState({
                    correct: this.props.settingsStatisticsData.overallCorrect,
                    incorrect: this.props.settingsStatisticsData.overallIncorrect,
                    favorited: this.props.settingsStatisticsData.overallFavorited,
                    unanswered: this.props.settingsStatisticsData.overallUnanswered,
                })
            }
        }

        if (prevProps.isStudying === true && this.props.isStudying === false) {
            this.props.updateShowOverallStats(true)
        }
    }

    toggleOverallStats = () => {
        if (this.props.showOverallStats) {
            this.setState({
                correct: this.props.settingsStatisticsData.currentCorrect,
                incorrect: this.props.settingsStatisticsData.currentIncorrect,
                favorited: this.props.settingsStatisticsData.currentFavorited,
                unanswered: this.props.settingsStatisticsData.currentUnanswered,
            }, () => {
                this.props.updateShowOverallStats(false)
            })
        } else {
            this.setState({
                correct: this.props.settingsStatisticsData.overallCorrect,
                incorrect: this.props.settingsStatisticsData.overallIncorrect,
                favorited: this.props.settingsStatisticsData.overallFavorited,
                unanswered: this.props.settingsStatisticsData.overallUnanswered,
            }, () => {
                this.props.updateShowOverallStats(true)
            })
        }
    }

    render() {
        return (
            <motion.div className='settingsStatisticsContainer' initial={this.props.pageAnimations ? appearTransition.initial : false} animate={this.props.pageAnimations ? appearTransition.in : false} exit={this.props.pageAnimations ? appearTransition.out : false} transition={{ duration: transitionDuration }} key='settingsStatisticsContainer'>
                <h2 className='settingsStatisticsHeader'>My Statistics</h2>
                <motion.div className='settingsStatisticsGraphContainer' initial={this.props.pageAnimations ? appearTransition.initial : false} animate={this.props.pageAnimations ? appearTransition.in : false} exit={this.props.pageAnimations ? appearTransition.out : false} transition={{ duration: transitionDuration }} key='settingsStatisticsGraph'>
                    <p className='settingsStatisticsGraphLabel'>Correct</p>
                    <p className='settingsStatisticsGraphLabel'>Incorrect</p>
                    <div className='settingsStatisticsGraph'>
                        <div className='settingsStatisticsGraphCorrectBar' style={{width: `calc(${this.state.incorrect + this.state.correct === 0 ? 0 : Math.floor(this.state.correct / (this.state.incorrect + this.state.correct) * 100)}% - 6px)`}}>
                            { (this.state.correct / (this.state.incorrect + this.state.correct) * 100) > 5 &&
                                <p className='settingsStatisticsGraphText graphTextCorrect'>{this.state.incorrect + this.state.correct === 0 ? 0 : Math.floor(this.state.correct / (this.state.incorrect + this.state.correct) * 100)}%</p>
                            }
                        </div>
                        <div className='settingsStatisticsGraphIncorrectBar' style={{width: `calc(${this.state.incorrect + this.state.correct === 0 ? 0 : Math.ceil(this.state.incorrect / (this.state.incorrect + this.state.correct) * 100)}% - 12px)`}}>
                            { (this.state.incorrect / (this.state.incorrect + this.state.correct) * 100) > 5 &&
                                <p className='settingsStatisticsGraphText'>{this.state.incorrect + this.state.correct === 0 ? 0 : Math.ceil(this.state.incorrect / (this.state.incorrect + this.state.correct) * 100)}%</p>
                            }
                        </div>
                    </div>
                </motion.div>
                <div className='settingsStatisticsIconWrapper'>
                    <div className='settingsStatisticsIconContainer'>
                        <FontAwesomeIcon icon={faSquareCheck} color={this.props.color} className='settingStatisticsIcon'/>
                        <p className='settingsStatisticsIconText'>{this.state.correct} Correct</p>
                    </div>
                    <div className='settingsStatisticsIconContainer'>
                        <FontAwesomeIcon icon={faStar} color={this.props.color} className='settingStatisticsIcon'/>
                        <p className='settingsStatisticsIconText'>{this.state.favorited} Favorites</p>
                    </div>
                    <div className='settingsStatisticsIconContainer'>
                        <FontAwesomeIcon icon={faSquareXmark} color={this.props.color} className='settingStatisticsIcon'/>
                        <p className='settingsStatisticsIconText'>{this.state.incorrect} Incorrect</p>
                    </div>
                    <div className='settingsStatisticsIconContainer'>
                        <FontAwesomeIcon icon={faClipboardList} color={this.props.color} className='settingStatisticsIcon'/>
                        <p className='settingsStatisticsIconText'>{this.state.unanswered} Incomplete</p>
                    </div>
                </div>
                {this.props.isStudying && this.props.displayStatsToggle &&
                    <div className='settingsStatisticsToggleWrapper'>
                        <p className='settingsStatisticsToggleText'>Show Overall Stats</p>
                        <FontAwesomeIcon icon={regular('circle-info')} onClick={(e) => this.infoClickHandler(e)} color={this.props.color} className='settingStatsInfoButton'/>
                        <Switch
                            otherAnimations={this.props.otherAnimations}
                            onClick={this.toggleOverallStats}
                            backgroundColor={this.props.showOverallStats ? this.props.color : ''}
                            value={this.props.showOverallStats}
                        />
                    </div>
                }
                <AnimatePresence exitBeforeEnter>
                    {this.state.displayInfoContainer &&
                        <motion.div className='settingsStatisticsInfoContainer' style={{top: this.state.statsInfoContainerTop}} initial={this.props.pageAnimations ? openTop.initial : false} animate={this.props.pageAnimations ? openTop.in : false} exit={this.props.pageAnimations ? openTop.out : false} transition={{ duration: transitionDuration }} key='settingsStatisticsInfoContainer'>
                            <p className='settingsStatisticsInfoText'>When "Show Overall Stats" is enabled, you will see your correct/incorrect score for all cards. When it is disabled, you will see only your correct/incorrect cards for your selected categories. If you have all categories selected, they will show the same amount</p>
                            <button className='settingsStatisticsInfoCloseButton primaryButton' onClick={() => this.setState({displayInfoContainer: false})}>Close</button>
                        </motion.div>
                    }
                </AnimatePresence>
            </motion.div>
        )
    }
}
