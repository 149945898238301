import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const displayNotification = (message, type = 0) => {
    switch(type) {
        case 0:
            toast(message)
            break
        default:
            toast('Invalid Type')
            break
    }
}

export default displayNotification