import React, { useState } from 'react'
import './SpacedModeSettings.css'
import { motion } from 'framer-motion'
import { pageTransition, pageTransitionDuration } from '../../Util/Animations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import handleError from '../../Util/HandleError'
import isLicenseExpired from '../../Util/IsLicenseExpired'
// import Switch from '../../Util/Switch/Switch'
import siteSettings from '../../siteSettings.json'

export default function SpacedModeSettings(props) {

    const handlePageChange = (e) => {
        e.stopPropagation()
        props.changePage('Categories')
    }

    const updateSpacedMultiplier = (e) => {
        let userData = {...props.userData}
        userData.ReviewMultiplier = Number(e.target.value)
        updateSpacedSettings(userData)
        props.updateUserData(userData)
        props.updateLoginCache(userData)
    }

    const updateInitialReviewPeriod = (e) => {
        let userData = {...props.userData}
        userData.InitialReviewPeriod = Number(e.target.value)
        updateSpacedSettings(userData)
        props.updateUserData(userData)
        props.updateLoginCache(userData)
    }

    const updateSpacedSettings = async (userData) => {
        let fetchBody = {
            customerId: userData.CustomerId,
            initialReviewPeriod: `${userData.InitialReviewPeriod}`,
            reviewMultiplier: `${userData.ReviewMultiplier}`
        }

        let spacedFetch = await fetch(`${siteSettings.apiEndpoint}/updatespacedsettings.webapi`, {
            method: 'POST',
            headers: {
            'Content-Type': 'text/plain',
            'Token': props.userData.Token
            },
            body: JSON.stringify(fetchBody)
        })

        if (spacedFetch.status === 401) {
            return props.displayUnauthenticatedHandler()
        }
        
        if (!spacedFetch.ok)
            return handleError('Failed to sync card scores')

        if (isLicenseExpired(props.userData.LicenseExpiration, props.userData.CurrentTime)) {
            return props.displayUnauthenticatedHandler(true)
        }
  
    //   let fetchData = await spacedFetch.text()
    }

    const [receivePushNotifications, togglePushNotifications] = useState(false)

    const updatePushNotifications = () => {
        togglePushNotifications(!receivePushNotifications)
        if (Notification.permission === "granted") {
            console.log('notification permissions granted')
            /* do our magic */
            // new Notification('Test Notification', {body: 'Test Notification Body'})
            navigator.serviceWorker.ready.then((reg) => {
                console.log('Got registration')
                reg.showNotification('Test Persistent Notification', {body: 'Test Notification Body'})
            })
        } else if (Notification.permission === "blocked") {
            /* the user has previously denied push. Can't reprompt. */
        } else {
            /* show a prompt to the user */
            Notification.requestPermission(function(status) {
                console.log('Notification permission status:', status);
            });
        }
    }

    return (
        <motion.div className='spacedModeSettingsPageWrapper' initial={props.pageAnimations ? pageTransition.initial : false} animate={props.pageAnimations ? pageTransition.in : false} exit={props.pageAnimations ? pageTransition.out : false} transition={{ duration: pageTransitionDuration }} key='SpacedModeSettings'>
            <button className='spacedModeSettingsBackButton' onClick={handlePageChange}><FontAwesomeIcon className='categoriesStartStudyingArrow' icon={faArrowLeft}/>Back</button>
            <h2 className='spacedModeSettingsTitle'>What is spaced mode?</h2>
            <p className='spacedModeSettingsText'>
                Spaced mode allows you to narrow down your studying to focus mainly on the cards you get wrong. For every card correct, you won’t see it again until after the duration of the day multiplier. Getting a card correct more than once will multiply the current duration by the multiplier amount.<br />
                <br />
                For example, if you get a card right for the 2nd time with a day multiplier of 3x, you won’t see the card again for 9 days, a 4th time for 27 days, and so on.<br />
            </p>
            <label className='spacedModeSettingsLabel'>Day Multiplier</label>
            <select className='spacedModeSettingsSelect' value={Number(props.userData.ReviewMultiplier)} onChange={updateSpacedMultiplier}>
                <option value={1}>1x</option>
                <option value={2}>2x</option>
                <option value={3}>3x</option>
                <option value={4}>4x</option>
                <option value={5}>5x</option>
            </select>
            <label className='spacedModeSettingsLabel'>Initial Review Period</label>
            <select className='spacedModeSettingsSelect' value={Number(props.userData.InitialReviewPeriod)} onChange={updateInitialReviewPeriod}>
                <option value={1}>1 Day</option>
                <option value={2}>2 Days</option>
                <option value={3}>3 Days</option>
                <option value={4}>4 Days</option>
                <option value={5}>5 Days</option>
            </select>
            {/* <label className='spacedModeSettingsSwitchLabel'>Receive Push Notifications</label> */}
            {/* <Switch
                otherAnimations={props.otherAnimations}
                onClick={() => togglePushNotifications(!receivePushNotifications)}
                backgroundColor={receivePushNotifications ? props.themeColor : ''}
                value={receivePushNotifications}
            /> */}
            {/* <div className='modeContainer spacedModeSettingsModeContainer'>
                <button className={`modeButton ${receivePushNotifications ? 'modeButtonSelected' : ''}`} onClick={() => updatePushNotifications(!receivePushNotifications)}>Enabled</button>
                <button className={`modeButton ${!receivePushNotifications ? 'modeButtonSelected' : ''}`} onClick={() => updatePushNotifications(!receivePushNotifications)}>Disabled</button>
            </div> */}
        </motion.div>
    )
}
